


















































































import Vue from "vue";
import TwitterIcon from "vue-material-design-icons/Twitter.vue";
import InstagramIcon from "vue-material-design-icons/Instagram.vue";
import DiscordIcon from "vue-material-design-icons/Discord.vue";

export default Vue.extend({
  components: {
    TwitterIcon,
    InstagramIcon,
    DiscordIcon,
  },
  props: {},
  data() {
    return {
      year: new Date().getFullYear(),
      browser: "Chrome"
    };
  },
  async mounted() {
    this.browser = this.$browserDetect.isFirefox ? "Firefox" : this.browser;
    this.browser = this.$browserDetect.isChrome ? "Chrome" : this.browser;
    this.browser = this.$browserDetect.isEdge ? "Edge" : this.browser;
  },
});
