import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isNavOpen: false,
  },
  mutations: {
    setIsNavOpen(state, val: boolean) {
      state.isNavOpen = val;
    }
  },
  actions: {
  },
  modules: {
  }
})
