import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

Vue.use(VueRouter);

const commonMetaData = [
  { name: 'description', content: 'Bkmark gives your team one place to save information, share ideas, and stay in sync across time and space. Private and ad-free.' },
  { name: 'keywords', content: 'bookmarks, bookmarks manager, links, shared links, knowledge base, read it later, remote teams' },
  { name: 'twitter:description', content: 'Bkmark gives your team one place to save information, share ideas, and stay in sync across time and space. Private and ad-free.' },
  { name: 'twitter:card', content: 'summary_large_image' },
  { name: 'twitter:site', content: '@BorisTane' },
  { name: 'twitter:title', content: 'Bkmark' },
  { name: 'twitter:creator', content: '@BorisTane' },
  { name: 'twitter:image', content: 'https://d1apvrodb6vxub.cloudfront.net/og-image.png' },
  { name: 'twitter:domain', content: 'bkmark.io' },
  { property: 'og:title', content: 'Bkmark | Tidy up bookmarks' },
  { property: 'og:url', content: 'https://bkmark.io' },
  { property: 'og:description', content: 'Bkmark gives your team one place to save information, share ideas, and stay in sync across time and space. Private and ad-free.' },
  { property: 'og:image', content: 'https://d1apvrodb6vxub.cloudfront.net/og-image.png' },
]

const r = [
  {
    path: "/",
    name: "Home",
    title: "Bkmark",
    component: "Home.vue",
    titleTemplate: "%s | Save, share and collaborate on your favourite web content",
  },
  {
    path: "/teams",
    name: "Teams",
    title: "Bkmark",
    component: "Teams.vue",
    titleTemplate: "%s | Collaborative bookmarks for teams",
  },
  {
    path: "/privacy",
    name: "Privacy",
    title: "Privacy Policy",
    component: "Privacy.vue",
  },
  {
    path: "/terms",
    name: "Terms",
    title: "Terms of Service",
    component: "Terms.vue",
  },
  {
    path: "/about",
    name: "About",
    title: "About",
    component: "About.vue",
  },
  {
    path: "/pricing",
    name: "Pricing",
    title: "Pricing",
    component: "Pricing.vue",
  },
  {
    path: "/bootstrapped",
    name: "Bootstrapped",
    title: "Bootstrapped",
    component: "Bootstrapped.vue",
  },
  {
    path: "/integrations",
    name: "Integrations",
    title: "Integrations",
    component: "Integrations.vue",
  },
  {
    path: "/download",
    name: "Download",
    title: "Download",
    component: "Download.vue",
  },
  {
    path: "/bookmarklet",
    name: "Bookmarklet",
    title: "Bookmarklet",
    component: "Bookmarklet.vue",
  },
  {
    path: "/vs/browser",
    name: "vs Browser",
    title: "Powerful Browser Bookmarks Alternative",
    component: "vs/Browser.vue",
  },
  {
    path: "/vs/pocket",
    name: "vs Pocket",
    title: "Modern Pocket Alternative",
    component: "vs/Pocket.vue",
  },
  {
    path: "/vs/notion",
    name: "vs Notion",
    title: "Notion for Bookmarks",
    component: "vs/Notion.vue",
  },
  {
    path: "/vs/spreadsheets",
    name: "vs Spreadsheets",
    title: "Ditch the Spreadsheets & Documents",
    component: "vs/Spreadsheets.vue",
  },
  {
    path: "/import/twitter",
    name: "Connect Twitter",
    title: "Connect your Twitter account to Bkmark",
    component: "import/Twitter.vue",
  },
  {
    path: "/import/slack",
    name: "Connect Slack",
    title: "Connect your Slack workspace to Bkmark",
    component: "import/Slack.vue",
  },
  {
    path: "/import/browser",
    name: "Import Chrome",
    title: "Import from Chrome to Bkmark",
    component: "import/Chrome.vue",
  },
  {
    path: "/blog",
    name: "Blog",
    title: "Blog",
    component: "UnderConstruction.vue",
  },
  {
    path: "/features",
    name: "Features",
    title: "Features",
    component: "Features.vue",
  },
  {
    path: "*",
    name: "Not Found",
    title: "Page Not Found",
    component: "NotFound.vue",
  }
]

const routes: Array<RouteConfig> = r.map(rr => {
  return {
    path: rr.path,
    name: rr.name,
    component: () => import(/* webpackChunkName: "about" */ `../views/${rr.component}`),
    meta: {
      title: rr.title,
      titleTemplate: rr.titleTemplate || "%s - Bkmark",
      meta: commonMetaData.map((elt) => {
        const data = { ...elt }
        if(elt.name?.includes("description") || elt.property?.includes("description")) {
          data.content += ` ${rr.title}`;
        }
        if(elt.name?.includes("title") || elt.property?.includes("title")) {
          data.content += ` - ${rr.title}`;
        }
        return data;
      }),
    },
  };
});
  


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
