













import Vue from "vue";
import Footer from "@/components/Footer.vue";
import Header from "@/components/Header.vue";
import Sidebar from '@/components/burger-menu/Sidebar.vue';
import HeaderMenuItems from "@/components/HeaderMenuItems.vue";
export default Vue.extend({
  components: {
    Footer,
    Header,
    Sidebar,
    HeaderMenuItems,
  },
  methods: {},
});
