














import Vue from "vue";
import Burger from "@/components/burger-menu/Burger.vue";
import HeaderMenuItems from "@/components/HeaderMenuItems.vue";
import { debounce } from "../utils/utils";

export default Vue.extend({
  components: {
    Burger,
    HeaderMenuItems,
  },
  props: {},
  data() {
    return {
      shadow: false,
    };
  },
  methods: {
    handleScroll() {
      debounce(() => {
        this.shadow = window.scrollY > 30;
      }, 50)();
    },
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
});
