




















































































import Vue from "vue";
import SignupButton from "@/components/SignupButton.vue";

export default Vue.extend({
  components: {
    SignupButton,
  },
  props: {
    vertical: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      isImportDropdownVisible: false,
      timeout: 0,
      browser: "Chrome",
      buttonText: "Start for free",
      isLoggedIn: false,
      creators: false,
    };
  },
  methods: {
    toggleImport(val: boolean) {
      clearTimeout(this.timeout);
      if (val) {
        this.isImportDropdownVisible = val;
      } else {
        this.timeout = setTimeout(() => {
          this.isImportDropdownVisible = val;
        }, 300);
      }
    },
    getImportImageUrl(): string {
      const images = require.context("../assets/images", false, /\.svg$/);
      return images(`./${this.browser.toLowerCase()}.svg`);
    },
    getCookie(cname: string) {
      const name = cname + "=";
      const ca = document.cookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
  },
  async mounted() {
    this.browser = this.$browserDetect.isFirefox ? "Firefox" : this.browser;
    this.browser = this.$browserDetect.isChrome ? "Chrome" : this.browser;
    this.browser = this.$browserDetect.isEdge ? "Edge" : this.browser;
    // this.browser = this.$browserDetect.isSafari ? "Safari" : this.browser;
  },
  beforeMount() {
    const token = this.getCookie("token");
    if (!token) return;
    this.isLoggedIn = true;
    this.buttonText = "Log In";
  },
});
