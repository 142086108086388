import Vue from 'vue';
import App from './App.vue';
import VueMeta from 'vue-meta';
import router from './router';
import store from './store';
import "./assets/scss/app.scss";
import VTitle from 'v-title';
import browserDetect from "vue-browser-detect-plugin";
import iframeResize from "iframe-resizer/js/iframeResizer";

Vue.config.productionTip = false;
Vue.use(VueMeta);
Vue.use(VTitle);
Vue.use(browserDetect);


Vue.directive('resize', {
  bind: function (el, { value = {} }) {
    el.addEventListener('load', () => iframeResize(value, el))
  },
  unbind: function (el) {
    //@ts-ignore
    el.iFrameResizer.removeListeners();
  }
})

new Vue({
  router,
  store,
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
}).$mount('#app');
