












import Vue from "vue";

export default Vue.extend({
  components: {},
  props: {
    light: {
      type: Boolean,
      required: false,
    },
    noShadow: {
      type: Boolean,
      required: false,
    },
    text: {
      type: String,
      required: false,
    },
    url: {
      type: String,
      required: false,
    },
    small: {
      type: Boolean,
      required: false,
    },
  },
  methods: {
    submit() {
      // @ts-ignore
      gtag("event", "submit", {
        event_category: "click",
      });
    },
  },
  data() {
    return {};
  },
});
